<script>
    import { fade } from "svelte/transition";
    export let resourceId = "";
    export let wantThumbnail = false;
</script>

{#if !wantThumbnail}
    <div class="image" transition:fade>
        <img
            src={`https://airgapflux.in/thumbnailcache/images_v2/${resourceId}.jpg`}
            alt="YouTube Thumbnail"
            loading="lazy"
        />
        <div class="play-button"></div>
    </div>
{/if}

<style>
    img {
        opacity: 0.7;
    }
</style>
